<template>
	<div>
		<div class="text-right mg-r-2 pd-y-10 fix-top-right">
			<a href="/leader-board" class="color-warning"><i class="el-icon-trophy-1"></i> Leader board</a>
		</div>
		<DefaultTemplate>
			<div class="mg-y-3">
				<div @click="singlePlayer">
					<el-button type="primary" plain round class="w-100 font-20" :loading="loadingNext">
						เล่นคนเดียว
					</el-button>
				</div>
			</div>
			<div>
				<router-link to="/join">
					<el-button type="primary" plain round class="font-20 w-100">
						เข้าร่วมห้อง
					</el-button>
				</router-link>
			</div>
		</DefaultTemplate>
	</div>
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	data(){
		return{
			loadingNext:false
		}
	},
	components:{
		DefaultTemplate
	},
	mounted() {
		this.$store.commit('SET_FINAN_PRODUCTS',null)
	},
	methods:{
		searchRoom(data){

			HTTP.post('/search/room',{number:data.roomNumber}).then(res=>{
  				if(res.data.success){
  					let update = {
  						label:'mode',
  						value:'single',
  					}
  					this.$store.commit('SET_ROOM',res.data.obj)
  					this.$store.commit('SET_ROOMDATA',update)
					this.$router.push('/start').catch(err => {})
				}
			}).finally(()=>{
				this.loadingNext = false
			}).catch(e=>{
  				console.log('/login error',e)
  				this.alertFailError()
  			})
		},
		createDemand(data){

			HTTP.post('/demand',data).then(respone=>{

				if(respone.status == 200){
					let current = {
						currentRound:1,
						mode:'single',
						current:"",
						_id:data._id,
					}
					this.$store.dispatch('updateCurrent',current)
					this.searchRoom(data)
				}
			}).catch(e=>{
				console.log('/demand error',e)
				this.alertFailError()
			})	
		},
		singlePlayer(){
			this.loadingNext = true
			let create = {
				amountUser:8,
				mode:'single'
			}
  			HTTP.post('/room',create).then(res=>{
  				if(res.data.success){
  					let demand = {
	      				roomId:res.data.obj._id,
						amount:8,
						...res.data.obj
	      			}
  					this.createDemand(demand)
  				}
  			}).finally(()=>{
  				this.loadingNext = false
  			}).catch(e=>{
  				console.log('/user error',e)
  				this.alertFailError()
  			})
		}
	}
}
</script>